const psl = require('psl')

let host = 'mng'

if (`${process.env.VUE_APP_IS_TENANT}` === '1') {
  const url = window.location.host
  if (url.indexOf(`${process.env.VUE_APP_DOMAIN}`) !== -1) {
    // eslint-disable-next-line prefer-destructuring
    host = url.split('.')[0]
  } else {
    const parsed = psl.parse(url)
    // eslint-disable-next-line
    host = parsed.sld
  }
}
// eslint-disable-next-line import/prefer-default-export
export const apiUrl = process.env.VUE_APP_API_URL
export const tenant = host
export const bucketUrl = process.env.VUE_APP_BUCKET_URL
export const defaultCompanyLogo = process.env.VUE_APP_BUCKET_URL + process.env.VUE_APP_DEFAULT_COMPANY_LOGO_PATH
export const appEnv = process.env.VUE_APP_ENV
