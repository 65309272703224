import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import moment from 'moment'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.mixin({
  methods: {
    showSuccessMessage(message, autoClose = true) {
      this.$swal({
        title: this.$t('Success!'),
        text: this.$t(message),
        icon: 'success',
        timer: autoClose ? 1500 : 60000,
        showCancelButton: false,
        showCloseButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    },
    showErrorMessage(message) {
      this.$swal({
        title: this.$t('Error!'),
        text: this.$t(message),
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'Okay',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    },
    // eslint-disable-next-line no-underscore-dangle
    __dateTimeFormatter(date) {
      if (date) {
        return moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss')
      }
      return ''
    },
    // eslint-disable-next-line no-underscore-dangle
    __dateTimeFormatterPassedTime(date) {
      const time = new Date(date)
      const now = new Date()
      const diff = now - time
      const seconds = Math.floor(diff / 1000)
      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)
      const months = Math.floor(days / 30)
      const years = Math.floor(months / 12)
      if (seconds < 60) return `${seconds}s ago`
      if (minutes < 60) return `${minutes}m ago`
      if (hours < 24) return `${hours}h ago`
      if (days < 30) return `${days}d ago`
      if (months < 12) return `${months}M ago`
      return `${years} years ago`
    },
    imageExists(imageUrl) {
      const http = new XMLHttpRequest()
      http.open('HEAD', imageUrl, false)
      http.send()
      return http.status !== 404
    },
  },
})
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
